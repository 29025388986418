import { useUserAccount } from '@/api/user/queries';
import NotificationContentModal from './Content';
import styles from './styles.module.scss';
import NotificationModalTabs from './Tabs';
import { PageDrawer } from '@starsoft/common/components';
import useTranslation from 'next-translate/useTranslation';
import { useModal } from '@starsoft/common/hooks';
import { ModalsKey } from '@/enums/modalsKey';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';

export default function NotificationsModal() {
  const { t } = useTranslation('common');
  const { isOpen, onClose } = useModal(ModalsKey.Notifications);
  const { isAuthorized } = useUserAccount();
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId = components.get(
    ComponentTypeId.Notifications,
  );

  return (
    <PageDrawer
      isOpen={isOpen && isAuthorized}
      onClose={onClose}
      title={t('tab_notification')}
      paddingless={version === SettingLayoutComponentId.NotificationsV2}
    >
      <div className={styles.container}>
        <NotificationModalTabs />
        <NotificationContentModal />
      </div>
    </PageDrawer>
  );
}
