import NavbarLink from '../Item/Link';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSearch } from '@/lib/store/casino/actions';
import { MouseEvent } from 'react';
import { RootState } from '@/lib/store';

export default function GameSearchLink() {
  const dispatch = useDispatch();
  const open: boolean = useSelector(
    (state: RootState) => state.casino.openSearch,
  );

  function onClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();

    if (open) {
      return;
    }

    dispatch(toggleSearch());
  }

  return (
    <NavbarLink
      label="search_placeholder"
      icon="fa-solid fa-magnifying-glass"
      href="#"
      onClick={onClick}
      hideActive
      disabled={open}
    />
  );
}
