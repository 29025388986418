import { MouseEvent, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSearchParams } from '../../useSearchParams';
import { usePathname } from '../../usePathname';
import { UseModalReturnProps } from './props';

export function useModal(modalKey: string): UseModalReturnProps {
  const { asPath, push } = useRouter();
  const search: URLSearchParams = useSearchParams();
  const pathname: string = usePathname();
  const isOpen: boolean = asPath?.includes(`#${modalKey}`);

  const onClose = useCallback(
    (clearParams: boolean = true) => {
      push(
        {
          pathname,
          search: clearParams ? undefined : search.toString(),
        },
        undefined,
        {
          scroll: false,
          shallow: true,
        },
      );
    },
    [pathname, search, push],
  );

  const setSearchParams = useCallback(
    <T,>(searchParamKey: string, value: T) => {
      search.set(searchParamKey, `${value}`);
      push(
        {
          search: search.toString(),
          hash: modalKey,
        },
        undefined,
        {
          shallow: true,
          scroll: false,
        },
      );
    },
    [search, modalKey, push],
  );

  function handleContainer(event: MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
  }

  return {
    isOpen,
    onClose,
    handleContainer,
    setSearchParams,
  };
}

export type { UseModalReturnProps };
