import { ModalHeaderProps } from './props';
import CloseButton from './CloseButton';
import styles from './styles.module.scss';
import { Button } from '../../Button';
import { useTranslation } from '../../../hooks';

export function ModalHeader({
  title,
  onClose,
  className = '',
  isLoading,
  handleGoBack,
  smallPadding,
  withBackButton,
  withoutPadding,
  hideCloseButton,
  smallMobilePadding,
}: ModalHeaderProps) {
  const { t } = useTranslation('common');

  function onGoBack() {
    if (handleGoBack) {
      handleGoBack();
      return;
    }
  }

  return (
    <div
      className={`${styles.container} ${withoutPadding ? styles['container--no-padding'] : ''} ${smallMobilePadding ? styles['container--small-mobile-padding'] : ''} ${smallPadding ? styles['container--small-padding'] : ''} ${className}`}
    >
      {withBackButton && (
        <Button
          type="button"
          isTiny
          onClick={onGoBack}
          isSecondary
          useMaxContent
          className={styles['container__back-button']}
        >
          <i className="fa-solid fa-arrow-left" />
          {t('back_button')}
        </Button>
      )}

      {isLoading ? (
        <div className={styles['title-skeleton']} />
      ) : (
        <h3 className={styles.container__title}>{title}</h3>
      )}

      {!hideCloseButton && (
        <div className={styles.container__close}>
          <CloseButton onClick={onClose} />
        </div>
      )}
    </div>
  );
}
