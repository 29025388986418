import styles from './styles.module.scss';
import MenuButton from './Menu/Button';
import GameSearchLink from './Search';
import NavbarLink from './Item/Link';
import { usePathname } from '@starsoft/common/hooks';

export default function MobileNavigationNavbar() {
  const pathname: string = usePathname();
  const isSportsPage: boolean = pathname.includes('/sports');

  return (
    <div className={styles.container}>
      <MenuButton />
      <GameSearchLink />
      <NavbarLink
        label="tournament_tabs"
        href="/tournaments"
        icon="fa-solid fa-trophy"
        detatchedIcon={!isSportsPage}
      />

      <NavbarLink
        label="tab_casino"
        icon="fa-solid fa-spade fa-swap-opacity"
        href="/casino"
      />
      <NavbarLink
        label="tab_sportsbook"
        icon="fa-solid fa-basketball"
        href="/sports"
      />

      {/* <LanguageButton />
      <NotificationButton /> */}
    </div>
  );
}
