import Link from '@/components/core/Link';
import { NavbarLinkProps } from './props';
import styles from '../styles.module.scss';
import { PropsWithChildren } from 'react';
import NavbarItemSkeleton from '../Skeleton';
import useTranslation from 'next-translate/useTranslation';
import { usePathname } from '@/hooks/usePathname';

export default function NavbarLink({
  disabled,
  className,
  children,
  isLoading,
  icon,
  label,
  href,
  detatchedIcon,
  hideActive,
  ...props
}: PropsWithChildren<NavbarLinkProps>) {
  const { t, lang } = useTranslation('common');
  const pathname: string = usePathname();

  if (isLoading) {
    return <NavbarItemSkeleton />;
  }

  return (
    <Link
      className={`${styles.container} ${(href === '/' ? href == pathname.replace(lang, '') : pathname.includes(href)) ? styles['container--active'] : ''} ${hideActive ? styles['container--hide'] : ''} ${disabled ? styles['container--disabled'] : ''} ${!!className ? className : ''}`}
      href={href}
      {...props}
    >
      {children}

      {icon ? (
        <div
          className={`${detatchedIcon ? styles['container__icon--detached'] : ''}`}
        >
          <i className={icon} />
        </div>
      ) : (
        <div />
      )}
      {t(label)}
    </Link>
  );
}
