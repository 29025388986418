import { useNotificationGroupsMe } from '@/api/notification-groups';
import { NotificationGroupId } from '@/enums/notificationGroupId';
import { RootState } from '@/lib/store';
import { useDispatch, useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { ErrorChip } from '@starsoft/common/components';
import { Tab } from '@starsoft/common/src/components/Tabs/Tab/props';
import { NotificationGroupWithUnreadCount } from '@/models/notification/notificationGroupWithUnreadCount';
import { useEffect, useMemo } from 'react';
import { setNotificationModalTab } from '@/lib/store/notifications/actions';
import NotificationModalTab from './Tab';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { ComponentTypeId } from '@starsoft/common/models';
import { TabsWithVersion } from '@/components/core/TabsWithVersion';
import { UseNotificationGroupsMeQueryKey } from '@/api/notification-groups/queries/useNotificationGroupsMe/props';
import { NotificationGroupsQueryKey } from '@/api/notification-groups/queryKey';
import { PaginatedResponse } from '@starsoft/common/models';
import { QueryClient, useQueryClient } from '@tanstack/react-query';

export default function NotificationModalTabs() {
  const tab: NotificationGroupId = useSelector(
    (state: RootState) => state.notifications.tab,
  );
  const dispatch = useDispatch();
  const { notificationGroups, isPending, error, refetch } =
    useNotificationGroupsMe();
  const queryClient: QueryClient = useQueryClient();
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId = components.get(
    ComponentTypeId.Notifications,
  );
  const tabs: Tab<NotificationGroupWithUnreadCount>[] = useMemo(
    () =>
      notificationGroups?.data
        .filter(
          (notificationGroup: NotificationGroupWithUnreadCount) =>
            notificationGroup.id === NotificationGroupId.Inbox,
        )
        .map((notificationGroup: NotificationGroupWithUnreadCount) => ({
          label: notificationGroup.name,
          value: notificationGroup,
        })) ?? [],
    [notificationGroups],
  );
  const currentTab: NotificationGroupWithUnreadCount | undefined = useMemo(
    () =>
      notificationGroups?.data.find(
        (notificationGroup: NotificationGroupWithUnreadCount) =>
          notificationGroup.id === tab,
      ),
    [notificationGroups, tab],
  );

  function notificationGroupsMeUpdater(
    notificationsGroups:
      | PaginatedResponse<NotificationGroupWithUnreadCount>
      | undefined,
  ) {
    if (!notificationsGroups) {
      return notificationsGroups;
    }

    const updatedNotificationGroupsData: NotificationGroupWithUnreadCount[] =
      notificationsGroups.data.map(notificationGroup => {
        return {
          ...notificationGroup,
          unreadCount: 0,
        };
      });

    return {
      ...notificationsGroups,
      data: updatedNotificationGroupsData,
    };
  }

  function onMount() {
    const notificationGroupsMeQueryKey: UseNotificationGroupsMeQueryKey = [
      NotificationGroupsQueryKey.Me,
      {
        page: 1,
        limit: 20,
        groupIds: [NotificationGroupId.Inbox, NotificationGroupId.Comissions],
      },
    ];
    queryClient.setQueriesData<
      PaginatedResponse<NotificationGroupWithUnreadCount>
    >(
      {
        queryKey: notificationGroupsMeQueryKey,
      },
      (
        notificationsGroup:
          | PaginatedResponse<NotificationGroupWithUnreadCount>
          | undefined,
      ) => notificationGroupsMeUpdater(notificationsGroup),
    );
  }

  //eslint-disable-next-line
  useEffect(onMount, []);

  function setTab(tab: NotificationGroupWithUnreadCount) {
    dispatch(setNotificationModalTab(tab.id));
  }

  if (error) {
    return (
      <div className={styles.container}>
        <ErrorChip action={refetch} cardBg />
      </div>
    );
  }

  if (isPending) {
    <div className={styles.container}>
      <div className={styles.skeleton} />
    </div>;
  }

  return (
    <div
      className={`${styles.container} ${version === SettingLayoutComponentId.NotificationsV2 ? styles['container--padding'] : ''}`}
    >
      <TabsWithVersion
        currentTab={currentTab as NotificationGroupWithUnreadCount}
        tabs={tabs}
        setTab={setTab}
        renderTab={props => <NotificationModalTab {...props} />}
      />
    </div>
  );
}
