import styles from '../styles.module.scss';
import contentStyles from '../Content/BaseContent/styles.module.scss';
import skeleton from './styles.module.scss';
import { useResolvedTheme } from '@/hooks/theme/useResolvedTheme';
import { SettingLayoutComponentId } from '@starsoft/common/models';
import { memo } from 'react';
import { ComponentTypeId } from '@starsoft/common/models';

function NotificationRowSkeleton() {
  const { components } = useResolvedTheme();
  const version: SettingLayoutComponentId =
    components.get(ComponentTypeId.Toasty) ?? SettingLayoutComponentId.ToastyV1;
  const notificationsVersion: SettingLayoutComponentId = components.get(
    ComponentTypeId.Notifications,
  );

  return (
    <div
      className={`${styles.container} ${styles['container--row']} ${notificationsVersion === SettingLayoutComponentId.NotificationsV2 ? styles['container--row--v2'] : ''}`}
    >
      <div className={`${skeleton.box} ${skeleton[`box--${version}`]}`} />
      <div
        className={`${contentStyles.column} ${contentStyles['column--row']}`}
      >
        <div className={skeleton.title} />
        {version === SettingLayoutComponentId.ToastyV1 ? (
          <div className={skeleton.description} />
        ) : (
          <div className={skeleton.date} />
        )}

        {version === SettingLayoutComponentId.ToastyV1 ? (
          <div className={skeleton.date} />
        ) : (
          <div className={skeleton.description} />
        )}
      </div>
    </div>
  );
}

export default memo(NotificationRowSkeleton);
